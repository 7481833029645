import { loadModules } from 'esri-loader';

const buildWidgets = (view) => {
    
    loadModules([
        "esri/widgets/Expand", 
        "esri/widgets/Legend",
        "esri/widgets/LayerList",
        "esri/widgets/Slider",
        "esri/widgets/Search",
        "esri/widgets/Home"
    ], { css: true }).then(([
        Expand, Legend, LayerList, Slider, Search, Home
    ]) => {
        
        const legend = new Expand({
            expandIconClass: "esri-icon-legend",  // see https://developers.arcgis.com/javascript/latest/guide/esri-icon-font/
            expandTooltip: "Expand Legend", 
            expanded: true,
            view: view,
            content: new Legend({
                view: view,
                hideLayersNotInCurrentView: true
            })
        });
        view.ui.add(legend, "bottom-left");
        
        const searchWidget = new Search({
            view: view
        });
        view.ui.add(searchWidget, {position: "top-left",index: 0});

        const layerList =  new LayerList({
            view: view,
            listItemCreatedFunction: function (event) {
                const item = event.item;
                // Adds a slider for updating a layer's opacity
                // if(item.layer.layerDef.layerType === 'groupLayer' || item.layer.layerDef.opacitySlider){
                  const slider = new Slider({
                    min: 0,
                    max: 1,
                    precision: 2,
                    values: [ item.layer.opacity ],
                    visibleElements: {
                      labels: true,
                      rangeLabels: true
                    }
                  });

                  item.panel = {
                    content: slider,
                    className: "esri-icon-sliders-horizontal",
                    title: "Change layer opacity"
                  }

                  slider.on("thumb-drag", (event) => {
                    const { value } = event;
                    item.layer.opacity = value;
                  })
                // }
            }
        });

        // layerList.on("trigger-action", (event) => {
        //     let layer = event.item.layer
        //     const id = event.action.id;

        //     if (id === "increase-opacity") {
        //       if (layer.opacity < 1) {
        //         layer.opacity += 0.1;
        //       }
        //     } else if (id === "decrease-opacity") {
        //       if (layer.opacity > 0) {
        //         layer.opacity -= 0.1;
        //       }
        //     }
        // });

        const layerListExpand = new Expand({
            expandIconClass: "esri-icon-layer-list", 
            expandTooltip: "Expand LayerList",
            expanded: true,
            view: view,
            content: layerList
        });
        view.ui.add(layerListExpand, "top-right");  
  
        let homeWidget = new Home({
            view: view
        });
        view.ui.add(homeWidget, "top-left");

    });

}



export { buildWidgets }