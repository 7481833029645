import React, { Component } from 'react';
import { NavLink, Link } from "react-router-dom";
import { Nav, Button, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from "@auth0/auth0-react";

const Header = () => {

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  return (
    <Nav id="header"
      className="navbar"
    >

      <img id="logo" className="mr-1" alt="logo" height="35px" src={process.env.PUBLIC_URL + '/logo.png'} />

      {/* <ul id="navbarLeft" className="navbar-nav flex-row justify-content-start flex-nowrap">
          <React.Fragment>
          <li className="nav-item">
              <NavLink to="/" exact activeClassName="active">
                Home
              </NavLink>
            </li>
            {isAuthenticated && (
              <li className="nav-item">
                <NavLink to="/Map" activeClassName="active">
                  Interactive Map
                </NavLink>
              </li>
            )}
          </React.Fragment>
      </ul> */}

      <ul id="navbarRight" className="navbar-nav ml-auto">
        {isAuthenticated && (
          <li className="nav-item">
            <FontAwesomeIcon
              id="iconUser"
              icon={faUser}
              className="mr-2"
            />
            <NavDropdown title='' id="loginDropdown" className='mt-2'>
              <span className="user-info m-2">
                <h6 className="d-inline-block">Logged in as {user.name}</h6>
              </span>
              <Button variant='warning' className="ml-2" onClick={() => logoutWithRedirect()}>Log out</Button>
            </NavDropdown>
          </li>
        )}
        
        {!isAuthenticated && (
          <li className="nav-item">
            <Button
              id="qsLoginBtn"
              color="primary"
              className="btn-margin"
              onClick={() => loginWithRedirect()}
            >
              Log in
            </Button>
          </li>
        )} 
      </ul>

      
    </Nav>
  )
}


export default Header ;
