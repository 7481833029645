const mapConfig = () => {

  return {
    minZoom: 0,
    maxZoom: 20,
    // center: [ 54.425, -2.968 ],
    center: [ -2.968, 54.425 ],
    spatialReference: {
      wkid: 3857
    },
    extent: {
      xmin: -28064,
      ymin: 15564,
      xmax: 1180221,
      ymax: 584490,
      spatialReference: {
        wkid: 27700
      }
    },
    zoom: 5,
    attributionControl: true,
    defaultOpacity: 0.95,
    proxyUrl: "./proxy/proxy.php",
    urlsToProxy: ['https://environment.data.gov.uk/spatialdata', 'http://lle.gov.wales'],
    mastermapUrl: 'https://api.os.uk/maps/vector/v1/vts',
    baseLayers: [

      
      {
        url: 'https://api.os.uk/maps/vector/v1/vts',
        layerType: 'agsVectorTileLayer',
        title: "MasterMap",
        blendMode: "darken"
      },
    ],
    layers: [
      {
        title: "Mining",
        layerType: "groupLayer",
        copyright: "Contains public sector information licensed under the Open Government Licence v3.0.",
        childLayers: [
          {
            title: "Coal mining reporting area",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/CoalAuthority/coalauthority_planning_policy_constraints/MapServer/WMSServer?",
            sublayers: [
              {
                name: "Coal.Mining.Reporting.Area",
                title: "Coal mining reporting area",
                listMode: "hide",
                identifyFields: [
                  {fieldName: "NAME", label: "Name"},
                  {fieldName: "FEATURE_TYPE", label: "Feature type"},
                  {fieldName: "HYPERLINK", label: "Hyperlink"}
                ],
                legendUrl: "https://map.bgs.ac.uk/HOSTED_WMS/legends/TCA/coal_mining_reporting_area.png"
              },
            ],
            maxScale: 23624.984411,
          },
          {
            title: "Mine Entries",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/CoalAuthority/coalauthority_mine_entries/MapServer/WMSServer?",
            sublayers: [
              
              {
                name: "Mine.Entry",
                title: "Mine entry",
                listMode: "hide",
                identifyFields: [
                  {fieldName: "FEATURE_TYPE", label: "Feature type"},
                  {fieldName: "HYPERLINK", label: "Hyperlink"}
                ],
                legendUrl: "https://map.bgs.ac.uk/HOSTED_WMS/legends/TCA/mine_entry.png"
              }
            ],
            maxScale: 23624.98441,
            minScale: 70870.535714
            
          },
          {
            title: "Abandoned mines catalogue",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/CoalAuthority/coalauthority_abandoned_mines/MapServer/WMSServer",
            minScale: 70870.535714,
            maxScale: 23624.984411,
            sublayers: [
              {
                name: "Abandoned.Mines.Catalogue",
                title: "Abandoned mines catalogue",
                listMode: "hide",
                legendUrl: "https://map.bgs.ac.uk/HOSTED_WMS/legends/TCA/abondoned_mines_catalogue.png",
                identifyFields: [
                  {fieldName: "FEATURE_TYPE", label: "Feature type"},
                  {fieldName: "CatalogueNumber", label: "Catalog No."},
                  {fieldName: "HYPERLINK", label: "Hyperlink"}
                ]
              }
            ]
          },
          {
            title: "Surface Coal Resource Areas",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/CoalAuthority/coalauthority_planning_policy_constraints/MapServer/WMSServer?",
            sublayers: [
              {
                name: "Surface.Coal.Resource.Areas",
                title: "Surface Coal Resource Areas",
                listMode: "hide",
                identifyFields: [
                  {fieldName: "FEATURE_TYPE", label: "Feature type"},
                  {fieldName: "HYPERLINK", label: "Hyperlink"}
                ],
                legendUrl: "https://map.bgs.ac.uk/HOSTED_WMS/legends/TCA/surface_coal_resource_areas.png"
              }
            ],
            maxScale: 23624.984411,
            minScale: 236235.119048
          },
          {        
            title: "Surface Mining (Past and Current)",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/CoalAuthority/coalauthority_planning_policy_constraints/MapServer/WMSServer?",
            sublayers: [
              
              {
                name: 'Surface.Mining.Past.and.Current',
                title: "Surface Mining (Past and Current)",
                listMode: "hide",
                identifyFields: [
                  {fieldName: "FEATURE_TYPE", label: "Feature type"},
                  {fieldName: "HYPERLINK", label: "Hyperlink"}
                ],
                legendUrl: "https://map.bgs.ac.uk/HOSTED_WMS/legends/TCA/surface_mining.png"
              }
            ],
            maxScale: 23624.984411,
            minScale: 70870.535714
          },        
          {
            title: "Development High Risk Area",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/CoalAuthority/coalauthority_planning_policy_constraints/MapServer/WMSServer?",
            sublayers: [
        
              {
                name: 'Development.High.Risk.Area',
                title: "Development High Risk Area",
                listMode: "hide",
                identifyFields: [
                  {fieldName: "FEATURE_TYPE", label: "Feature type"},
                  {fieldName: "HYPERLINK", label: "Hyperlink"}
                ],
                legendUrl: "https://map.bgs.ac.uk/HOSTED_WMS/legends/TCA/devel_high_risk_area.png"
              }
            ],
            maxScale: 23624.984411,
            minScale: 236235.119048,
          },
          {
            title: "Mine entry potential zone of influence",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/CoalAuthority/coalauthority_specific_risk/MapServer/WMSServer?",
            minScale: 70870.535714,
            maxScale: 23624.984411,
            sublayers: [
              {
                name: "Mine.Entry.Potential.Zone.of.Influence",
                title: "Mine entry potential zone of influence",
                listMode: "hide",
                legendUrl: "https://map.bgs.ac.uk/HOSTED_WMS/legends/TCA/mine_entry_zoi.png",
                identifyFields: [
                  {fieldName: "FEATURE_TYPE", label: "Feature type"},
                  {fieldName: "HYPERLINK", label: "Hyperlink"}
                ]
              }
            ],
          },
          {
            title: "Fissures and breaklines",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/CoalAuthority/coalauthority_specific_risk/MapServer/WMSServer?",
            minScale: 70870.535714,
            maxScale: 23624.984411,
            sublayers: [
              {
                name: "Fissures.and.Breaklines",
                title: "Fissures and breaklines",
                listMode: "hide",
                legendUrl: "https://map.bgs.ac.uk/HOSTED_WMS/legends/TCA/fissures.png",
                identifyFields: [
                  {fieldName: "FEATURE_TYPE", label: "Feature type"},
                  {fieldName: "HYPERLINK", label: "Hyperlink"}
                ]
              }
            ]
          },
          {
            title: "Past shallow coal mine workings",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/CoalAuthority/coalauthority_specific_risk/MapServer/WMSServer?",
            minScale: 70870.535714,
            maxScale: 23624.984411,
            sublayers: [
              {
                name: "Past.Shallow.Coal.Mine.Workings",
                title: "Past shallow coal mine workings",
                listMode: "hide",
                legendUrl: 'https://map.bgs.ac.uk/HOSTED_WMS/legends/TCA/past_shallow_coal_mine_workings.png',
                identifyFields: [
                  {fieldName: "FEATURE_TYPE", label: "Feature type"},
                  {fieldName: "HYPERLINK", label: "Hyperlink"}
                ]
              }
            ]
          },
          {
            title: "Probable shallow coal mine workings",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/CoalAuthority/coalauthority_specific_risk/MapServer/WMSServer?",
            minScale: 70870.535714,
            maxScale: 23624.984411,
            sublayers: [
              {
                name: "Probable.Shallow.Coal.Mine.Workings",
                title: "Probable shallow coal mine workings",
                listMode: "hide",
                legendUrl: 'https://map.bgs.ac.uk/HOSTED_WMS/legends/TCA/probable_shallow_coal_mine_workings.png',
                identifyFields: [
                  {fieldName: "FEATURE_TYPE", label: "Feature type"},
                  {fieldName: "HYPERLINK", label: "Hyperlink"}
                ]
              }
            ]
          },
          {
            title: "Coal outcrops",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/CoalAuthority/coalauthority_specific_risk/MapServer/WMSServer?",
            minScale: 70870.535714,
            maxScale: 23624.984411,
            sublayers: [
              {
                name: "Coal.Outcrops",
                title: "Coal outcrops",
                listMode: "hide",
                legendUrl: 'https://map.bgs.ac.uk/HOSTED_WMS/legends/TCA/coal_outcrops.png',
                identifyFields: [
                  {fieldName: "FEATURE_TYPE", label: "Feature type"},
                  {fieldName: "HYPERLINK", label: "Hyperlink"}
                ]
              }
            ]
          }
        ]
      },

      {
        title: "Cultural Designations (England)",
        layerType: "groupLayer",
        childLayers: [
          {
            title: "World Heritage Sites (England)",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/HE/WorldHeritageSites/FeatureServer/0",
            identifyFields: [
              {fieldName: 'name', label: "Name"}
            ],
            copyright: "Contains public sector information licensed under the Open Government Licence v3.0."
          },
          {
            title: "Listed buildings",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/HE/ListedBuildings/FeatureServer/0",
            identifyFields: [
              { fieldName: "name", label: "Name"},
              { fieldName: "location", label: "Location"},
              { fieldName: "grade", label: "Grade"}
            ],
            copyright: "Contains public sector information licensed under the Open Government Licence v3.0."
          },
          {
            title: "Conservation areas (England)",
            layerType: "wmsLayer",
            url: "https://environment.data.gov.uk/spatialdata/he-conservation-areas/wms",
            copyright: "Contains public sector information licensed under the Open Government Licence v3.0.",
            sublayers: [
              {
                name: "HE_Conservation_Areas",
                title: "Conservation areas (England)",
                identifyFields: [
                  {fieldName: "name", label: "Name"},
                  {fieldName: "lpa", label: "LPA"},
                  {fieldName: "DATEOFDESI", label: "Designation date"}, 
                ],
                listMode: 'hide'
              }
            ]
          },
          {
            title: "Scheduled Ancient Monuments (England)",
            layerType: "wmsLayer",
            url: "https://environment.data.gov.uk/spatialdata/scheduled-monuments/wms",
            sublayers: [
              {
                name: "Scheduled_Monuments",
                title: "Scheduled Ancient Monuments (England)",
                identifyFields: [
                  {fieldName: "name", label: "Name"},
                  {fieldName: "scheddate", label: "Scheduled date"},
                  {fieldName: "hyperlink", label: "Link"},
                ],
                listMode: 'hide',
                copyright: "Contains public sector information licensed under the Open Government Licence v3.0."
              }
            ]
          },
          {
            title: "Registered Parks and Gardens",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/HE/RegisteredParksAndGardens/FeatureServer/0",
            identifyFields: [
              {fieldName: "name", label: "Name"},
              {fieldName: "grade", label: "Grade"}
            ],
            copyright: "Contains public sector information licensed under the Open Government Licence v3.0."
          },
        ]
      },

      { 
        title: "Environmental Designations (England)",
        layerType: "groupLayer",
        childLayers:[
          {
            title: "Sites of special scientific interest",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/NE/SitesOfSpecialScientificInterestEngland/FeatureServer/0",
            identifyFields: [
              {fieldName: "sssi_name", label: "Name"},
              {fieldName: "sssi_area", label: "Area", format: {places: 1, digitSeparator: true}},
              {fieldName: "status", label: "Status"}
            ],
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]"
          },
          {
            title: "Sites of Special Scientific Interest Units (England)",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/NE/SitesOfSpecialScientificInterestUnitsEngland/FeatureServer/0",
            identifyFields: [
              {fieldName: "sssi_name", label: "Name"},
              {fieldName: "unit_area", label: "Area", format: {places: 1, digitSeparator: true}},
              {fieldName: "status", label: "Status"}
            ],
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]"
          },
          {
            title: "Ramsar (England)",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/NE/RamsarEngland/FeatureServer/0",
            identifyFields: [
              {fieldName: "name", label: "Name"},
              {fieldName: "ne.ne_admin.ne_ramsar_england.area", label: "Area", format: {places: 1, digitSeparator: true}},
              {fieldName: "code", label: "Code"}
            ],
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]"
          },
          {
            title: "Special areas of conservation (England)",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/NE/SpecialAreasOfConservationEngland/FeatureServer/0",
            identifyFields: [
              {fieldName: "sac_name", label: "Name"},
              {fieldName: "sac_area", label: "Area", format: {places: 1, digitSeparator: true}},
              {fieldName: "sac_code", label: "Code"}
            ],
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]"
          },
          {
            title: "Special Protection Areas (England)",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/NE/SpecialProtectionAreasEngland/FeatureServer/0",
            identifyFields: [
              {fieldName: "spa_name", label: "Name"},
              {fieldName: "spa_area", label: "Area", format: {places: 1, digitSeparator: true}},
              {fieldName: "spa_code", label: "Code"}
            ],
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]"
          },
          {
            title: "Local Nature Reserves (England)",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/NE/LocalNatureReservesEngland/FeatureServer/0",
            identifyFields: [
              {fieldName: "lnr_name", label: "Name"},
              {fieldName: "lnr_area", label: "Area", format: {places: 1, digitSeparator: true}},
              {fieldName: "status", label: "Status"}
            ],
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]"
          },
          {
            title: "National Nature Reserves (England)",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/NE/NationalNatureReservesEngland/FeatureServer/0",
            identifyFields: [
              {fieldName: "nnr_name", label: "Name"},
              {fieldName: "nnr_area", label: "Area", format: {places: 1, digitSeparator: true}},
              {fieldName: "status", label: "Status"}
            ],
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]",
          },
          {
            title: "Ancient Woodland (England)",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/NE/AncientWoodlandEngland/FeatureServer/0",
            identifyFields: [
              {fieldName: "name", label: "Name"},
              {fieldName: "ne.ne_admin.ne_ancient_woodland_england.area", label: "Area", format: {places: 1, digitSeparator: true}},
              {fieldName: "theme", label: "Theme"}
            ],
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]",
          },
          {
            title: "National Parks (England)",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/NE/NationalParksEngland/FeatureServer/0",
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]",
            identifyFields: [
              {fieldName: "name", label: "Name"},
              {fieldName: "measure", label: "Measure", format: {places: 1, digitSeparator: true}},
              {fieldName: "status", label: "Status"},
              {fieldName: "hotlink", label: "Hyperlink"}
            ]
          }, 
          {
            title: "Areas of outstanding natural beauty",
            layerType: "wmsLayer",
            url: "https://environment.data.gov.uk/spatialdata/areas-of-outstanding-natural-beauty-england/wms",
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]",
            sublayers: [
              {
                name: "Areas_of_Outstanding_Natural_Beauty_England",
                title: "Areas of outstanding natural beauty",
                listMode: 'hide',
                identifyFields: [
                  {fieldName: "name", label: "Name"},
                  {fieldName: "desig_date", label: "Designation date"},
                  {fieldName: "stat_area", label: "Area"},
                  {fieldName: "hotlink", label: "Link"},
                ]
              }
            ]
          },
          {
            title: "Potential Special Protection Areas (England)",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/NE/PotentialSpecialProtectionAreasEngland/FeatureServer/0",
            identifyFields: [
              {fieldName: "spa_name", label: "Name"},
              {fieldName: "spa_area", label: "Area", format: {places: 1, digitSeparator: true}},
              {fieldName: "spa_code", label: "Code"},
              {fieldName: "status", label: "Status"}
            ],
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]"
          },
          {
            title: "Possible areas of conservation (England)",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/NE/PossibleSpecialAreasOfConservationEngland/FeatureServer/0",
            identifyFields: [
              {fieldName: "name", label: "Name"},
              {fieldName: "site_ha", label: "Area (ha)", format: {places: 1, digitSeparator: true}},
              {fieldName: "pa_code", label: "Code"},
              {fieldName: "status", label: "Status"}
            ],
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]"
          },

        ]
      },

      { 
        title: "Environmental Designations (Wales)",
        layerType: "groupLayer",
        childLayers:[
          {
            title: "Sites of Special Scientific Interest (Wales)",
            layerType: "wmsLayer",
            url: "http://lle.gov.wales/services/wms/nrw",
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]",
            sublayers: [
              {
                name: "NRW_SSSI",
                title: "Sites of Special Scientific Interest (Wales)",
                listMode: 'hide'
              }
            ]
          },
          {
            title: "Ramsar (Wales)",
            layerType: "wmsLayer",
            url: "http://lle.gov.wales/services/wms/nrw",
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]",
            sublayers: [
              {
                name: "NRW_RAMSAR",
                title: "Ramsar (Wales)",
                listMode: 'hide'
              }
            ]
          },
          {
            title: "Special areas of conservation (Wales)",
            layerType: "wmsLayer",
            url: "http://lle.gov.wales/services/wms/nrw",
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]",
            sublayers: [
              {
                name: "NRW_SAC",
                title: "Special areas of conservation (Wales)",
                listMode: 'hide'
              }
            ]
          },
          {
            title: "Special protection areas (Wales)",
            layerType: "wmsLayer",
            url: "http://lle.gov.wales/services/wms/nrw",
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]",
            sublayers: [
              {
                name: "NRW_SPA",
                title: "Special protection areas (Wales)",
                listMode: 'hide'
              }
            ]
          },
          {
            title: "Local nature reserves (Wales)",
            layerType: "wmsLayer",
            url: "http://lle.gov.wales/services/wms/nrw",
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]",
            sublayers: [
              {
                name: "NRW_LNR",
                title: "Local nature reserves (Wales)",
                listMode: 'hide'
              }
            ]
          },
          {
            title: "National nature reserves (Wales)",
            layerType: "wmsLayer",
            url: "http://lle.gov.wales/services/wms/nrw",
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]",
            sublayers: [
              {
                name: "NRW_NNR",
                title: "National nature reserves (Wales)",
                listMode: 'hide'
              }
            ]
          },
          {
            title: "Ancient woodland (Wales)",
            layerType: "wmsLayer",
            url: "http://lle.gov.wales/services/wms/nrw",
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]",
            sublayers: [
              {
                name: "NRW_ANCIENT_WOODLAND_INVENTORY_2021",
                title: "Ancient woodland (Wales)",
                listMode: 'hide'
              }
            ]
          },
          {
            title: "Area of Outstanding Natural Beauty (Wales)",
            layerType: "wmsLayer",
            url: "http://lle.gov.wales/services/wms/nrw",
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]",
            sublayers: [
              {
                name: "NRW_AONB",
                title: "Area of Outstanding Natural Beauty (Wales)",
                listMode: 'hide'
              }
            ]
          },
          {
            title: "National Parks (Wales)",
            layerType: "wmsLayer",
            url: "http://lle.gov.wales/services/wms/nrw",
            copyright: "© Natural England copyright. Contains Ordnance Survey data © Crown copyright and database right [year]",
            sublayers: [
              {
                name: "NRW_NATIONAL_PARK",
                title: "National Parks (Wales)",
                listMode: 'hide'
              }
            ]
          },
        ]
      },
      { 
        title: "Cultural Designations (Wales)",
        layerType: "groupLayer",
        childLayers:[
          {
            title: "World Heritage Sites (Wales)",
            layerType: "wmsLayer",
            url: "http://lle.gov.wales/services/wms/wg",
            copyright: "© Crown copyright",
            sublayers: [
              {
                name: "WorldHeritageSites",
                title: "World Heritage Sites (Wales)",
                listMode: 'hide'
              }
            ]
          },
          {
            title: "Listed buildings (Wales)",
            layerType: "wmsLayer",
            url: "https://datamap.gov.wales/geoserver/inspire-wg/wms",
            copyright: "© Crown copyright",
            sublayers: [
              {
                name: "Cadw_ListedBuildings",
                title: "Listed buildings (Wales)",
                listMode: 'hide'
              }
            ]
          },
        
          {
            title: "Conservation areas (Wales)",
            layerType: "wmsLayer",
            url: "https://datamap.gov.wales/geoserver/inspire-wg/wms",
            copyright: "© Crown copyright",
            sublayers: [
              {
                name: "conservation_areas",
                title: "Conservation areas (Wales)",
                listMode: 'hide'
              }
            ]
          },
        ]

      },

      { 
        title: "Landfill",
        layerType: "groupLayer",
        childLayers:[
          {
            title: "Historic landfill",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/EA/HistoricLandfill/FeatureServer/0",
            identifyFields: [
              {fieldName: "site_name", label: "Site name"},
              {fieldName: "site_add", label: "Address"},
              {fieldName: "site_ref", label: "Reference"},
              {fieldName: "lic_hold", label: "License holder"}
            ],
            copyright: "© Environment Agency copyright and/or database right 2018. All rights reserved.  Contains information © Local Authorities "
          }, 
          {
            title: "Authorised landfill",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/EA/PermittedWasteSitesAuthorisedLandfillSiteBoundaries/FeatureServer/0",
            identifyFields: [
              {fieldName: "lic_name", label: "Licensed name"},
              {fieldName: "lic_site", label: "Licensed site"},
              {fieldName: "type_desc", label: "Description"}
            ],
            copyright: "© Environment Agency copyright and/or database right 2015. All rights reserved."
          }
        ]
      },

      {
        title: "Flood",
        layerType: "groupLayer",
        childLayers: [
          {
            title: "Flood Zone 2",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/EA/FloodMapForPlanningRiversAndSeaFloodZone2/FeatureServer/0",
            copyright: "© Environment Agency copyright and/or database right 2018. All rights reserved. Some features of this map are based on digital spatial data from the Centre for Ecology & Hydrology, © NERC (CEH). © Crown copyright and database rights 2018 Ordnance Survey 100024198y",
            identifyFields: [
              { fieldName: "type", label: "Type"}
            ] 
          },
          {
            title: "Flood Zone 3",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/EA/FloodMapForPlanningRiversAndSeaFloodZone3/FeatureServer/0",
            copyright: "© Environment Agency copyright and/or database right 2018. All rights reserved. Some features of this map are based on digital spatial data from the Centre for Ecology & Hydrology, © NERC (CEH). © Crown copyright and database rights 2018 Ordnance Survey 100024198y",
            identifyFields: [
              { fieldName: "type", label: "Type"}
            ] 
          },
          {
            title: "Surface Flood Extent: Low Risk",
            layerType: "wmsLayer",
            url: "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-extent-0-1-percent-annual-chance/wms",
            sublayers: [
              {
                name: "1",
                title: "Surface Flood Extent: Low Risk",
                listMode: 'hide'
              }
            ],
            copyright: "© Environment Agency copyright and/or database right 2018. All rights reserved. Some features of this map are based on digital spatial data from the Centre for Ecology & Hydrology, © NERC (CEH). © Crown copyright and database rights 2018 Ordnance Survey 100024198y",
          },
          {
            title: "Surface Flood Extent: Medium Risk",
            layerType: "wmsLayer",
            url: "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-extent-1-percent-annual-chance/wms",
            sublayers: [
              {
                name: "1",
                title: "Surface Flood Extent: Medium Risk",
                listMode: 'hide'
              }
            ],
            copyright: "© Environment Agency copyright and/or database right 2018. All rights reserved. Some features of this map are based on digital spatial data from the Centre for Ecology & Hydrology, © NERC (CEH). © Crown copyright and database rights 2018 Ordnance Survey 100024198y",
          },
          {
            title: "Surface Flood Extent: High Risk",
            layerType: "wmsLayer",
            url: "https://environment.data.gov.uk/spatialdata/risk-of-flooding-from-surface-water-extent-3-3-percent-annual-chance/wms",
            sublayers: [
              {
                name: "1",
                title: "Surface Flood Extent: High Risk",
                listMode: 'hide'
              }
            ],
            copyright: "© Environment Agency copyright and/or database right 2018. All rights reserved. Some features of this map are based on digital spatial data from the Centre for Ecology & Hydrology, © NERC (CEH). © Crown copyright and database rights 2018 Ordnance Survey 100024198y",
          }
        ]
      },

      {
        title: "Geology",
        layerType: "groupLayer",
        childLayers: [
          {
            title: "Linear features",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/BGS_Detailed_Geology/MapServer/WMSServer?",
            sublayers: [
              {
                name:"BGS.50k.Linear.features",
                title: "Linear features",
                identifyFields: [
                  {fieldName: "CATEGORY", label: "Category"},
                  {fieldName: "FEATURE_D", label: "Feature"}
                ],
                listMode: 'hide'
              }
            ],
            copyright: "Contains British Geological Survey materials © UKRI [year]",
            minScale: 94494.992560
          },
          {
            title: "Superficial deposits",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/BGS_Detailed_Geology/MapServer/WMSServer?",
            sublayers: [
              {
                name: "BGS.50k.Superficial.deposits",
                title: "Superficial deposits",
                identifyFields: [
                  {fieldName: "TYPE_D", label: "Type"},
                  {fieldName: "ENVIRONMENT_D", label: "Environment"}
                ],
                listMode: 'hide'
              }
            ],
            copyright: "Contains British Geological Survey materials © UKRI [year]",
            minScale: 94494.992560
          },
          {
            title: "Bedrock",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/BGS_Detailed_Geology/MapServer/WMSServer?",
            sublayers: [
              {
                name: "BGS.50k.Bedrock",
                title: "Bedrock",
                listMode: 'hide',
                identifyFields: [
                  {fieldName: "TYPE_D", label: "Type"},
                  {fieldName: "ENVIRONMENT_D", label: "Environment"}
                ]
              }
            ],
            minScale: 94494.992560,
            copyright: "Contains British Geological Survey materials © UKRI [year]",
          },
          {
            title: "Artificial ground",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/BGS_Detailed_Geology/MapServer/WMSServer?",
            sublayers: [
              {
                name: "BGS.50k.Artificial.ground",
                title: "Artificial ground",
                identifyFields: [
                  {fieldName: "TYPE_D", label: "Type"},
                  {fieldName: "ENVIRONMENT_D", label: "Environment"}
                ],
                legendUrl: "https://map.bgs.ac.uk/bgs_wms/legends/artificial.gif",
                listMode: 'hide'
              }
            ],
            minScale: 94494.992560,
            copyright: "Contains British Geological Survey materials © UKRI [year]",
          },
          {
            title: "Mass movement",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/BGS_Detailed_Geology/MapServer/WMSServer?",
            sublayers: [
              {
                name: "BGS.50k.Mass.movement",
                title: "Mass movement",
                identifyFields: [
                  {fieldName: "TYPE_D", label: "Type"},
                  {fieldName: "ENVIRONMENT_D", label: "Environment"}
                ],
                legendUrl: "https://map.bgs.ac.uk/bgs_wms/legends/mass_movement.gif",
                listMode: 'hide'
              }
            ],
            minScale: 94494.992560,
            copyright: "Contains British Geological Survey materials © UKRI [year]",
          },
          {
            title: "BGS Boreholes",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/GeoIndex_Onshore/boreholes/MapServer/WmsServer?",
            sublayers: [
              {
                name: 'Borehole.records',
                title: "BGS Boreholes",
                listMode: 'hide'
              }
            ],
            minScale: 236235.119048
          },
          {
            title: "Indicative Atlas of Radon",
            layerType: "wmsLayer",
            url: "https://map.bgs.ac.uk/arcgis/services/GeoIndex_Onshore/radon/MapServer/WmsServer",
            sublayers: [
              {
                name: "Radon.1km",
                title: "Indicative Atlas of Radon",
                identifyFields: [
                  {fieldName: "Description", label: "Description"}
                ],
                listMode: 'hide',
                legendUrl: "https://map.bgs.ac.uk/arcgis/services/GeoIndex_Onshore/radon/MapServer/WmsServer?request=GetLegendGraphic%26version=1.3.0%26format=image/png%26layer=Radon.1km"
              }
            ],
            copyright: "Contains British Geological Survey materials © UKRI [year]",
          },          
          {
            title: "Source protection zones",
            layerType: "agsFeatureLayer",
            url: "https://environment.data.gov.uk/arcgis/rest/services/EA/SourceProtectionZonesMerged/FeatureServer/0",
            copyright: "© Environment Agency copyright and/or database right 2016. All rights reserved.",
            identifyFields: [
              { fieldName: "number", label: "number"}
            ],
            copyright: "© Environment Agency copyright and/or database right 2016. All rights reserved.",
          },
        ]
      },

      {
        title: "Historic maps",
        layerType: "groupLayer",
        copyright: "National Library of Scotland (nls.uk)",
        visibilityMode: "exclusive",
        childLayers: [
          {
            title: "OS One-inch to the mile (1:63,360), 'Hills' edition, 1885-1903",
            layerType: "webTileLayer",
            urlTemplate: "https://api.maptiler.com/tiles/uk-osgb63k1885/{z}/{x}/{y}.png?key=4YqBAg5cCbtEfJ8jbG8f",
          },
          {
            title: "OS Six-inch to the mile (1:10,560), 1888-1913",
            layerType: "webTileLayer",
            urlTemplate: "https://api.maptiler.com/tiles/uk-osgb10k1888/{z}/{x}/{y}.jpg?key=4YqBAg5cCbtEfJ8jbG8f",
          },
          {
            title: "London, OS Five-foot to the mile (1:1,056), 1893-1896",
            layerType: "webTileLayer",
            urlTemplate: "https://api.maptiler.com/tiles/uk-oslondon1k1893/{z}/{x}/{y}.jpg?key=4YqBAg5cCbtEfJ8jbG8f",
            minScale: 20000,
          },
          {
            title: "OS 'Provisional' edition (1:25,000), 1937-1961",
            layerType: "webTileLayer",
            urlTemplate: "https://api.maptiler.com/tiles/uk-osgb25k1937/{z}/{x}/{y}.jpg?key=4YqBAg5cCbtEfJ8jbG8f",
          },
          {
            title: "OS One-Inch Seventh Series (1:63,360), 1955-1961",
            layerType: "webTileLayer",
            urlTemplate: "https://api.maptiler.com/tiles/uk-osgb63k1955/{z}/{x}/{y}.jpg?key=4YqBAg5cCbtEfJ8jbG8f",
          },
          {
            title: "OS Historical Maps from 1919-1947",
            layerType: "webTileLayer",
            urlTemplate: "https://api.maptiler.com/tiles/uk-osgb1919/{z}/{x}/{y}.jpg?key=4YqBAg5cCbtEfJ8jbG8f",
          },
          {
            title: "OS (various scales, 1900s)",
            layerType: "webTileLayer",
            urlTemplate: "https://api.maptiler.com/tiles/uk-osgb1888/{z}/{x}/{y}?key=4YqBAg5cCbtEfJ8jbG8f",
          },

          

        ]
      },
    ]
  };
}

export { mapConfig }
