import React from 'react';
import { Container, Jumbotron, Row, Col, Button } from 'react-bootstrap';

const Landing = () => {
  return (
    <React.Fragment>
      <Container fluid className="h-100">
        <Jumbotron className='mt-5'>
          <Row>
            <Col md={6} xs={12}>
              <h1>Interactive data for early site assessment</h1>
              <p className="mt-5">Landmark View is a comprehensive geospatial data viewer to support site evaluation and due diligence.</p> 
              <p>Professionals working in land, property, insurance and environmental consulting can use Landmark View to access and interact with key data from the British Geological Survey (BGS), the Coal Authority, Natural England, Historic England and the Environment Agency in order to assess project viability and potential costs.</p> 
              <p>Data content can be viewed interactively over either the latest OS Mastermap® vector background mapping or referenced against Landmark’s market-leading collection of historic maps.</p> 
              <h2 className='mt-5'>How to access Landmark View</h2>
              <p>If you need an account, speak to your Client Director today to get access credentials added to your current Landmark Enterprise agreement.</p> 
              <p>Landmark View is also available via subscription. If you would like to subscribe please contact us at <a href="mailto:sales@promap.co.uk?subject=Landmark View enquiry">sales@promap.co.uk</a> or call us on 0330 036 6627.</p>
            </Col>
            <Col md={6} xs={12}>
              <img id="logo" className="mr-1 landingImg" alt="logo" src='https://www.landmark.co.uk/wp-content/uploads/2021/03/LM-Product-LAPTOP-PM-Layers-1024x683.png' />
            </Col>
          </Row>
        </Jumbotron>
        <footer className="footer mt-auto pt-3">
          <div className="container">
            <Row className="mt-3">
              <Col md={6} lg={3}>
              
                <a href="https://www.landmark.co.uk" className="footer-logo">
                  <img alt="Landmark Information Group" src="https://www.landmark.co.uk/wp-content/themes/landmark/images/landmark-logo-white.svg"/>
                </a>
                <div className="footer-legal">
                  <p>©2022 Landmark Information Group Ltd.</p>
                </div>
              </Col>

              <Col md={6} lg={9}>
                <ul className="footer-nav">
                  <li> <a href="https://www.landmark.co.uk/our-group/contact/"><h3>Contact Us</h3></a></li>
                  <li>
                    <span>Tel: </span>
                    <a href="tel:+44 3300 366000">+44 3300 366000</a>
                  </li>
                  <li>
                    <span>Fax: </span>
                    <a href="tel:+44 1392 441 709">+44 1392 441 709</a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </footer>
      </Container>
    </React.Fragment>
  )
}

export default Landing;
