import React, { Component } from 'react';
import { mapConfig } from '../config/mapConfig.js';
import { Container, Row} from 'react-bootstrap';
import { loadModules } from 'esri-loader';
import { buildWidgets } from '../modules/widgets.js';
import { buildLayers } from '../modules/layers.js';
import { handleViewClick } from '../modules/utils.js';

class InteractiveMap extends Component {

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();

    this.state = {
      mapIsUpdating: false
    }
  }

  componentDidMount(){
    console.log("Map page mounted")
    this.loadMap()
  }

  loadMap(){
    console.log("load map")
    
    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules(
      [
        "esri/Map",
        "esri/views/MapView",
        "esri/layers/VectorTileLayer",
        "esri/layers/FeatureLayer",
        "esri/config",
        "esri/geometry/Extent",
        "esri/core/watchUtils",
        "esri/core/urlUtils"
    ], { css: true })
    .then((
      [
        Map, MapView, VectorTileLayer, FeatureLayer, esriConfig, Extent, watchUtils, urlUtils
      ]) => {

      // Add the OS API key and BNG options to the request URL before it is sent.
      const { REACT_APP_OS_API_KEY } = process.env; // Ordnance Survey API key is an env variable
      esriConfig.request.interceptors.push({
        urls: mapConfig().mastermapUrl,
        before: function(params) {
          if(! params.requestOptions.query ) {
            params.requestOptions.query = {};
          }
          params.requestOptions.query.key = REACT_APP_OS_API_KEY;
          params.requestOptions.query.srs = 3857;
        }
      });

      // add the historic maps subscription key
      // esriConfig.request.interceptors.push({
      //   urls: mapConfig().historicUrl,
      //   before: function(params) {
      //     if(! params.requestOptions.query ) {
      //       params.requestOptions.query = {};
      //     }
      //     params.requestOptions.query['subscription-key'] = '700e6082797746e18d8084a27ab35842';
      //   }
      // });

      // If we are not in development mode, set a proxy for certain layers
      // const { REACT_APP_ENVIRONMENT } = process.env;
      // console.log("configuring reverse proxy")
      // if (typeof REACT_APP_ENVIRONMENT === 'undefined') {
        mapConfig().urlsToProxy.forEach(urlToProxy => {
          urlUtils.addProxyRule({
            urlPrefix: urlToProxy,
            proxyUrl: mapConfig().proxyUrl
          });
        })
      // }

      // Build the basemap layers from the config file
      let baseLayers = mapConfig().baseLayers.map(baseLayer => {
        if (baseLayer.layerType === 'agsVectorTileLayer'){
          return new VectorTileLayer(baseLayer);
        } else if (baseLayer.layerType === 'agsFeatureLayer'){
          return new FeatureLayer(baseLayer)
        } else {
          console.error("unknown base layer type");
        }
      })

      let map = new Map({
        basemap: {
          baseLayers: baseLayers
        },
      });

      let view = new MapView({
        container: this.mapRef.current,
        map: map,

        zoom: mapConfig().zoom,
        center: mapConfig().center,
        minZoom: mapConfig().minZoom,
        maxZoom: mapConfig().maxZoom,
        // spatialReference: mapConfig().spatialReference,

        // background: {
        //   color: "#a0d7eb" // to match the ocean in the OS Vector Tile layer
        // },
        constraints: {
          minZoom: mapConfig().minZoom,
          maxZoom: mapConfig().maxZoom,
          rotationEnabled: false,
          // geometry: new Extent(mapConfig().extent)
        },
        popup: {
          dockEnabled: false,
          dockOptions: {
            buttonEnabled: false,
            breakpoint: false
          }
        }
      });

      // Show/hide the loading indicator when the view is updating
      watchUtils.whenTrue(view, "updating", evt => {
        this.setState({mapIsUpdating: true});
      });

      watchUtils.whenFalse(view, "updating", evt => {
        this.setState({mapIsUpdating: false});
      });

      watchUtils.whenTrue(view, "stationary", () => {
        console.log("Zoom level:", view.scale)
      });

      view.when(() => {
          buildWidgets(view);
          buildLayers(view.map);
          
          // When clicking on the map, run an Identify on any visible WMS layers
          view.on("click", (event) => {
            handleViewClick(event, view);
          });

          // view.popup.watch("visible", (popupVisible) => {
          //   // Remove the Identify graphic when the popup opens
          //   if (popupVisible) {
          //     view.graphics.removeAll();
          //   }
          // });

      })
      
    })    
    
  } 


  render() {
    

    return (
      <Container fluid className="h-100">
        <Row className="h-100">


          <div className="col col-lg-12">
              <div
                id="mainMap"
                className="base-container"
                ref={this.mapRef}
                // onLoad={this.handleViewLoad}
                // onError={this.handleFail}
              />
              {this.state.mapIsUpdating ?  <img id='loading' src="./loading.gif"/> : null}             
          </div>
        </Row>
       
        

        </Container>
    )
  }
}

export default InteractiveMap;
