import { mapConfig } from '../config/mapConfig.js';
import { loadModules } from 'esri-loader';

async function buildLayers(map) {
    
    mapConfig().layers.forEach(layerDef => {
        let newLayer;
        switch (layerDef.layerType){
            case "groupLayer":
                if (!layerDef.visible) {
                    layerDef.visible = false;
                }
                newLayer = buildGroupLayer(layerDef);

                // Create any child layers of the group, and add them to the group (rather than the map)
                newLayer.then(newLayerResponse => {
                    // Bind the layerDef to the layer for later retrieval
                    newLayerResponse.layerDef = layerDef;
                    layerDef.childLayers.forEach(childLayerDef => {
                        let childLayer = buildLayer(childLayerDef);
                        childLayer.then(childLayerResponse => {
                            // Bind the layerDef to the layer for later retrieval
                            childLayerResponse.layerDef = childLayerDef;
                        })
                        newLayerResponse.add(childLayer);
                    })
                })

                break;
            default:
                newLayer = buildLayer(layerDef);
                newLayer.then(newLayerResponse => {
                    // Bind the layerDef to the layer for later retrieval
                    newLayerResponse.layerDef = layerDef;
                });
        }

        map.add(newLayer);
    })
}

async function buildLayer(layerDef) {
    let newLayer;
    // Set default opacity, and switch layers off by default
    if (!layerDef.opacity){
        layerDef.opacity = mapConfig().defaultOpacity;
    }
    if (!layerDef.visible) {
        layerDef.visible = true;
    }
    switch (layerDef.layerType){
        case "agsFeatureLayer":
            newLayer = buildFeatureLayer(layerDef);
            break;
        case "agsMapImageLayer":
            newLayer = buildMapImageLayer(layerDef);
            break;
        case "wmsLayer":
            newLayer = await buildWMSLayer(layerDef);
            break;
        case "wmtsLayer":
            newLayer = buildWMTSLayer(layerDef);
            break;
        case "agsVectorTileLayer":
            newLayer = buildVectorTileLayer(layerDef);
            break;
        case "geoJSONlayer":
            newLayer = buildGeoJSONLayer(layerDef);
            break;
        case "webTileLayer":
            newLayer = buildWebTileLayer(layerDef);
            break;
        default:
            console.error("unknown layer type", layerDef)
    }
    return newLayer;
}

function buildGroupLayer(layerDef){
    return loadModules(["esri/layers/GroupLayer"]).then(([GroupLayer]) => {
        return new GroupLayer(layerDef);
    });
}

const buildFeatureLayer = (layerDef) => {
    // lazy load the required ArcGIS API for JavaScript modules and CSS
    return loadModules(["esri/layers/FeatureLayer"], { css: false }).then(([FeatureLayer]) => {
        return new FeatureLayer(layerDef);
    });
}

const buildMapImageLayer = (layerDef) => {
    return loadModules(["esri/layers/MapImageLayer"], { css: false}).then(([MapImageLayer]) => {
        return new MapImageLayer(layerDef);
    })
}

function buildWMSLayer(layerDef) {
    return loadModules(["esri/layers/WMSLayer"], { css: false }).then(([WMSLayer]) => {
        return new WMSLayer(layerDef);
    });
}

async function buildWMTSLayer(layerDef) {
    return loadModules(["esri/layers/WMTSLayer"], { css: false }).then(([WMTSLayer]) => {
        return new WMTSLayer(layerDef);
    });
}

const buildVectorTileLayer = (layerDef) => {
    return loadModules(["esri/layers/VectorTileLayer"], { css: false }).then(([VectorTileLayer]) => {
        return new VectorTileLayer(layerDef);
    });
}

const buildGeoJSONLayer = (layerDef) => {
    return loadModules(["esri/layers/GeoJSONLayer"], { css: false }).then(([GeoJSONLayer]) => {
        return new GeoJSONLayer(layerDef);
    });
}

const buildWebTileLayer = (layerDef) => {
    return loadModules(["esri/layers/WebTileLayer"], { css: false }).then(([WebTileLayer]) => {
        return new WebTileLayer(layerDef);
    });
}

export { buildLayers }