import axios from 'axios';
import XMLParser from 'react-xml-parser';
import { loadModules } from 'esri-loader';
import { mapConfig } from '../config/mapConfig';

const getUrlParameter = (sParam) => {
  var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
          return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
  }
};

function checkIdentify(layer, view){
    // decide whether Identify should run on this layer
    // Only query visible layers with identifyFields configured, within their scale range
    layer.runIdentify = false;

    let layerDef = layer.layerDef;
    if (!layerDef){ return; }
    if (['wmsLayer', 'wmtsLayer'].includes(layerDef.layerType)){
        // check if any sublayers have identifyFields configured
        try{
            if (layer.visible) {
                layer.runIdentify = (layerDef.sublayers.find(o => o.identifyFields !== null) != null);
            }
        } catch(err){
            console.error(err.message);
        }
    } else if (layerDef.layerType === 'groupLayer'){
        if (layer.visible){
            layer.layers.forEach(childLayer => {
                checkIdentify(childLayer, view);
            })
        } else {
            layer.layers.forEach(childLayer => {
                childLayer.runIdentify = false;
            })
        }   
    } else if (layerDef.identifyFields) {
        layer.runIdentify = layer.visible;
    }

    if (layer.minScale && layer.minScale > 0 && view.scale >= layer.minScale) {
        layer.runIdentify = false;
    }
    if (layer.maxScale && layer.maxScale > 0 && view.scale <= layer.maxScale) {
        layer.runIdentify = false;
    }
    if (layer.runIdentify){
        if (layer.sublayers) {
            layer.sublayers.forEach(subLayer => {
                subLayer.runIdentify = subLayer.visible
                if (subLayer.minScale && subLayer.minScale > 0 && view.scale >= subLayer.minScale) {
                    subLayer.runIdentify = false;
                }
                if (subLayer.maxScale && subLayer.maxScale > 0 && view.scale <= subLayer.maxScale) {
                    subLayer.runIdentify = false;
                }
                if (subLayer.runIdentify){
                    view.visibleLayersCount += 1;
                }                        
            })
        } else {
            view.visibleLayersCount += 1;
        }
    }            
}

function handleViewClick (event, view) {
    loadModules(["esri/Graphic", "esri/symbols/PictureMarkerSymbol"]).then(([Graphic, PictureMarkerSymbol]) => {
        // When clicking on the map, run an Identify on any visible WMS layers
        console.log("click")

        // Add a point to show the location clicked
        // view.graphics.removeAll()
        // const pointGraphic = new Graphic({
        //     geometry: event.mapPoint,
        //     symbol: {
        //         type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
        //         color: [226, 119, 40],
        //         outline: {
        //             // autocasts as new SimpleLineSymbol()
        //             color: [255, 255, 255],
        //             width: 2
        //         }
        //     }
        // });  
        // view.graphics.add(pointGraphic);

        // Keep track of the visible layers, so that each visible feature can be
        // shown in the view's popup
        view.identifyGraphics = [];
        view.identifyCount = 0;
        view.visibleLayersCount = 0;
        view.map.layers.items.forEach(layer => {
            checkIdentify(layer, view);            
        })

        // Run an Identify query on applicable layers/sublayers
        view.map.layers.items.forEach(layer => {

            if (layer.runIdentify){
                runIdentify(layer, view, event);
            } else if (layer.type === 'group') {
                layer.layers.forEach(childLayer => {
                    if (childLayer.runIdentify) {
                        runIdentify(childLayer, view, event);
                    }
                })
            }
        
        });
    })
}

function runIdentify(layer, view, event){
    // Run the appropriate Identify operation (depends on the layer type) at the location clicked
    if (layer.layerType === 'wmsLayer') {
        let data = {
            REQUEST: 'GetFeatureInfo',
            SERVICE: 'WMS',
            VERSION: '1.1.1',
            SRS: 'EPSG:' + view.spatialReference.wkid,
            BBOX: view.extent.xmin.toString() + "," + view.extent.ymin.toString() + "," + view.extent.xmax.toString() + "," + view.extent.ymax.toString(),
            WIDTH: view.size[0],
            HEIGHT: view.size[1],
            X: parseInt(event.screenPoint.x),
            Y: parseInt(event.screenPoint.y)
        }
        layer.sublayers.items.map(subLayer => {
            if (subLayer.runIdentify){
                data['QUERY_LAYERS'] = subLayer.name;
                runGetFeatureInfo(layer, data, view, event.mapPoint).then(handleWMSQueryResponse);
            }
        })

    } else if (layer.layerType === 'wmtsLayer'){
        let data = {
            REQUEST: 'GetFeatureInfo',
            SERVICE: 'WMS',
            VERSION: '1.1.1',
            SRS: 'EPSG:' + view.spatialReference.wkid,
            BBOX: view.extent.xmin.toString() + "," + view.extent.ymin.toString() + "," + view.extent.xmax.toString() + "," + view.extent.ymax.toString(),
            WIDTH: view.size[0],
            HEIGHT: view.size[1],
            X: parseInt(event.screenPoint.x),
            Y: parseInt(event.screenPoint.y)
        }
        data['LAYERS'] = layer.activeLayer.id;
        data['QUERY_LAYERS'] = layer.activeLayer.id;

        // verify this section - it has not been tested yet with WMTS
        runGetFeatureInfo(layer, data, view, event.mapPoint).then(handleWMSQueryResponse);

    } else if (layer.layerType === 'agsFeatureLayer') {
        queryFeatureLayer(layer, view, event.mapPoint);
    }
}

async function runGetFeatureInfo (layer, data, view, mapPoint) {
        // Perform an AJAX query against the GetFeatureInfo endpoint of WMS/WMTS layers
        console.log("get feature info", layer)
        let response;
        let useProxy = false;
        mapConfig().urlsToProxy.forEach(urlToProxy => {
            if (layer.url.indexOf(urlToProxy) > -1){
                useProxy = true;
            }
        })
        let url;
        if (useProxy){
            url = mapConfig().proxyUrl + "?" + layer.url;
        } else {
            url = layer.url;
        }

        // Build the URL request from the parameters
        Object.keys(data).forEach((key, index)=> {
            if (index === 0) {
                if (url.slice(url.length -1) === "?") {
                    url += key + "=" + data[key];
                } else {
                    url += "?" + key + "=" + data[key];
                }
            } else {
                url += "&" + key + "=" + data[key];
            }
        });

        // Run the request
        response = await axios.get(url);

        // Pass the view and mapPoint along with the response so we can access it later
        response.view = view;
        response.layerName = layer.layerDef.sublayers[0].name;
        response.mapPoint = mapPoint;
        return response;
}

async function queryFeatureLayer(layer, view, mapPoint){
    // Perform a queryFeatures operation on any Feature Layers (this is used instead
    // of a simple PopupTemplate against the feature layer, in order to reuse the same
    // popup window for feature layers and non-feature layers like WMS)
    const query = {
        geometry: mapPoint,
        outSpatialReference: view.spatialReference,
        returnGeometry: false,
        outFields: [ "*" ]
    }
    let features = await layer.queryFeatures(query).then(results => {
        return results.features;
    });
    handleFeatureLayerResponse(features, view, mapPoint)
}

function handleFeatureLayerResponse(features, view, mapPoint){
    loadModules(["esri/Graphic"]).then(([Graphic]) => {
        view.identifyCount += 1;
        features.forEach(feature => {
            // Find the matching layer, and hence its config, in order to get its Identify fields
            let layer = feature.layer;
            let layerDef = layer.layerDef;

            let graphic = new Graphic({
                geometry: {
                    type: "point",
                    x: mapPoint.x,
                    y: mapPoint.y,
                    spatialReference: mapPoint.spatialReference
                },
                symbol: {
                    type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
                    color: [226, 119, 40],
                    outline: {
                      // autocasts as new SimpleLineSymbol()
                      color: [255, 255, 255],
                      width: 2
                    }
                },
                attributes: feature.attributes,
                popupTemplate: {
                    title: layerDef.title,
                    content: [{
                        type: "fields",
                        fieldInfos: layerDef.identifyFields
                    }]
                }
            });
            view.identifyGraphics.push(graphic);
        })

        openPopup(view, mapPoint);
    });
}

function handleWMSQueryResponse(response){
    loadModules(["esri/Graphic"]).then(([Graphic]) => {
        try{

            var layerName = response.layerName; //.config.params.QUERY_LAYERS;
            let data = new XMLParser().parseFromString(response.data);
            let mapPoint = response.mapPoint;
            let view = response.view;
            view.identifyCount += 1;
            
            if (data.children.length > 0){
                let layer = data.children[0];

                // Find the layer definition. This may be attached to the layer, or its child layers in the case of a group layer
                let wmsLayers = mapConfig().layers.filter(o => (o.layerType === 'wmsLayer' || o.layerType === 'wmtsLayer'));
                let groupLayers = mapConfig().layers.filter(o => (o.layerType === 'groupLayer'));
                groupLayers.forEach(groupLayer => {
                    groupLayer.childLayers.forEach(childLayer => {
                        if (childLayer.layerType === 'wmsLayer' || childLayer.layerType === 'wmtsLayer'){
                            wmsLayers.push(childLayer);
                        }
                    })
                })
                let layerDef;
                wmsLayers.forEach(wmsLayer => {
                    if (wmsLayer.sublayers) {
                        wmsLayer.sublayers.forEach(subLayer => {
                            if (subLayer.name === layerName){
                                layerDef = subLayer;
                            }
                        })
                    }
                });

                let graphic = new Graphic({
                    geometry: {
                        type: "point",
                        x: mapPoint.x,
                        y: mapPoint.y,
                        spatialReference: mapPoint.spatialReference
                    },
                    symbol: {
                        type: "simple-marker",
                        color: [226, 119, 40],
                        outline: {
                            color: [255, 255, 255],
                            width: 2
                        }
                    },
                    attributes: layer.attributes,
                    popupTemplate: {
                        title: layerDef.title,
                        content: [{
                            type: "fields",
                            fieldInfos: layerDef.identifyFields
                        }]
                    }
                });
                view.identifyGraphics.push(graphic);
            }
            
            openPopup(view, mapPoint);
        } catch(error){
            console.error("There was a problem displaying the popup for this WMS layer")
        }
    });
    
}

function openPopup(view, mapPoint){
    if (view.identifyCount >= view.visibleLayersCount) {
        if (view.identifyGraphics.length > 0){
            view.popup.open({
                location: mapPoint,
                features: view.identifyGraphics
            });
        } else {
            view.popup.open({
                location: mapPoint,
                content: "No features found at this location"
            });
        }
    }
    
}


export { getUrlParameter, handleViewClick }
