import React from 'react';
import Header  from "./components/Header";
import Landing from "./Landing";
import MapComponent from './components/Map';
import { Container, Row} from 'react-bootstrap';
import './App.css';
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";
import Loading from "./components/Loading";

const App = () => {
    const { isLoading, error, isAuthenticated } = useAuth0();

    if (error) {
      return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div id="main">
      <Header/>
      {isAuthenticated ?
        <Container fluid className="h-100">
          <Row className="h-100">
          <MapComponent />
          </Row>
        </Container>
      : <Landing/>}
      </div>
    )
  }

  export default App;

